@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.ifood-benefits-container {
  display: block;
  position: relative;
  background-color: #69022d;
  background-image: url('/static/images/benefits/bg-benefits-card.svg');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto auto;
  min-height: 414px;
  width: 100%;

  @media screen and (min-width: 1000px) {
    display: none;
  }

  &-description-woman-img {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  @media screen and (max-width: 700px) {
    &-description {
      margin: 0;
      margin-top: 96px;

      img {
        height: auto;
      }
    }
  }

  a {
    text-decoration: none;
    display: flex;
  }

  &-description {
    margin: 96px 0 0 52px;

    p {
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;
      color: #fdf8f1;
      max-width: 378px;
    }

    &-logo {
      filter: invert(1) brightness(1000%);
      width: 165px;
    }
  }

  @media screen and (max-width: 425px) {
    &-description-woman-img {
      width: 340px;
    }
  }

  @media screen and (max-width: 890px) {
    background-image: url('/static/images/benefits/bg-benefits-card-mobile.svg');

    a {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      margin: 0;
    }

    &-description-woman-img {
      position: inherit;
    }
  }
}

.ifood-benefits {
  width: 100%;

  @media screen and (max-width: 1000px) {
    display: none;
  }

  a {
    text-decoration: none;
    width: 100%;
    height: 106px;
  }

  display: flex;
  justify-content: center;

  &-banner {
    display: flex;
    position: relative;
    width: 100%;
    background-color: #69022d;
    height: 106px;

    svg {
      color: #69022d;
    }

    &-logo {
      background-color: #fdf8f1;
      width: 50%;
      display: flex;
      align-items: center;
      border-bottom-right-radius: 100px;
      height: 106px;
      z-index: 2;

      &-image {
        width: 220px;
        margin-left: 58px;
        margin-right: 60px;
      }

      &-card {
        width: 190px;
        left: 38%;
        position: absolute;
        bottom: 0;
      }
    }

    &-description {
      width: 533px;
      display: flex;
      place-content: center;
      height: 106px;
      z-index: 1;
      align-items: center;
      justify-content: center;

      img {
        width: 181px;
      }

      p {
        color: #fdf8f1;
        width: 224px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 62px;

        strong {
          font-weight: 700;
        }
      }
    }
  }
}
