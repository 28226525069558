@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-merchants {
  padding: 28px 16px;

  @include mq('tablet') {
    padding: 50px 45px 40px;
  }

  @include mq('desktop-s') {
    padding: 70px 45px 50px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 70px 32px 50px;
  }

  @media only screen and (min-width: 1278px) {
    padding: 70px 0 50px;
  }

  &__title {
    font-size: rem(18);
    line-height: rem(22);
    font-weight: 500;
    color: $gray-color-700;
    margin: 0;
    padding: 20px 0;
    border-top: 2px solid $light-grey-color-100;

    @include mq('mobile') {
      font-size: rem(20);
      line-height: rem(26);
    }

    @include mq('tablet') {
      font-size: rem(24);
      line-height: rem(30);
    }

    @include mq('desktop-s') {
      padding: 50px 0 32px;
    }

    @include mq('desktop') {
      padding-top: 60px;
    }
  }

  .marmita-scrollbox {
    height: auto;
    margin: 0 -16px;
    width: calc(100% + 32px);

    @include mq('tablet') {
      margin: 0 -45px;
      width: calc(100% + 90px);
    }

    @media only screen and (min-width: 1024px) {
      margin: 0 -32px;
      width: calc(100% + 64px);
    }

    @media only screen and (min-width: 1278px) {
      margin: 0;
      width: 100%;
    }

    &__wrapper {
      padding-bottom: 0;
    }

    &__item {
      margin-right: 16px;

      &:first-child {
        margin-left: 16px;

        @include mq('tablet') {
          margin-left: 45px;
        }

        @media only screen and (min-width: 1024px) {
          margin-left: 32px;
        }

        @media only screen and (min-width: 1278px) {
          margin-left: 0;
        }
      }

      &:last-child {
        @include mq('tablet') {
          margin-right: 45px;
        }

        @media only screen and (min-width: 1024px) {
          margin-right: 32px;
        }

        @media only screen and (min-width: 1278px) {
          margin-right: 0;
        }
      }
    }
  }

  .carousel__controller {
    &--right {
      right: -22px;
      transform: translateY(-18px) rotateZ(-90deg);
    }

    &--left {
      left: -22px;
      transform: translateY(-18px) rotateZ(90deg);
    }
  }
}
