@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-ifood-link {
  background-image: url('/static/images/landing-v2/link-background.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: -20px 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mq('mobile') {
    background-size: 333px 248px;
    background-position: 50%;
  }

  @include mq('tablet') {
    width: 85%;
    height: auto;
    padding-bottom: 20px;
    background-position: 30px 20px;
    background-size: 55% auto;
  }

  @include mq('desktop-s') {
    background-size: 70% auto;
    background-position: 0 85%;
    width: 48%;
    padding-bottom: 55px;
  }

  @include mq('desktop') {
    width: 45%;
    background-position: 0 68%;
    padding-bottom: 100px;
    background-size: 85% auto;
  }

  &--delivery-man {
    @include mq('tablet') {
      padding-bottom: 40px;
    }

    @include mq('desktop-s') {
      padding-bottom: 55px;
    }

    @include mq('desktop') {
      padding-bottom: 100px;
    }
  }

  &__image {
    margin: 0;
    position: absolute;
    width: 147px;
    height: auto;
    left: -40px;
    bottom: 0;

    img {
      width: 100%;
    }

    @include mq('mobile') {
      left: 10px;
      bottom: -5px;
    }

    @include mq('tablet') {
      width: 235px;
      bottom: 0;
    }

    @include mq('desktop-s') {
      width: 50%;

      img {
        width: 85%;
      }
    }

    @include mq('desktop') {
      bottom: 25px;
    }
  }

  &__content {
    padding: 35px 0;
    width: 62%;

    @include mq('mobile') {
      width: 55%;
    }

    @include mq('tablet') {
      width: 50%;
    }
  }

  &__title {
    font-size: rem(20);
    color: $gray-color-700;
    font-weight: 500;
    line-height: rem(26);
    margin: 12px 80px 0 0;

    @include mq('tablet') {
      font-size: rem(24);
      line-height: rem(30);
    }

    @include mq('desktop-s') {
      font-size: rem(36);
      line-height: rem(44);
      margin: 12px 0 0;
    }
  }

  &__description {
    color: $text-color;
    font-size: rem(14);
    line-height: rem(18);
    margin-right: 24px;

    @include mq('tablet') {
      font-size: rem(16);
      line-height: rem(20);
      margin-top: 25px;
      width: 60%;
    }

    @include mq('desktop-s') {
      width: 90%;
    }
  }

  &__button {
    width: 114px;
    height: 34px;
    font-size: rem(14);
    cursor: pointer;

    @include mq('tablet') {
      width: 138px;
      height: 48px;
      font-size: rem(18);
    }
  }
}
