@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-merchant {
  position: relative;
  width: 200px;
  height: 100%;
  padding: 20px 16px;
  border-radius: 8px;
  border: 1px solid $light-grey-color-300;
  display: grid;
  grid-template-columns: 46px 1fr;
  column-gap: 8px;
  align-items: center;
  transition: border-color 350ms ease-in-out;
  text-decoration: none;

  @include mq('desktop') {
    grid-template-columns: 56px 1fr;
  }

  &:hover {
    border-color: $gray-color-lighten;
  }

  @include mq('desktop-s') {
    width: 250px;
    height: 132px;
  }

  &__logo {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 1px solid $light-grey-color-300;

    @include mq('desktop') {
      width: 56px;
      height: 56px;
    }
  }

  &__name {
    margin: 0;
    font-size: rem(14);
    line-height: rem(18);
    font-weight: 400;
    color: $gray-color-700;
  }

  &__description {
    margin: 2px 0 0;
    font-size: rem(12);
    line-height: rem(16);
    color: $text-color;
  }

  &__super {
    position: absolute;
    top: 8px;
    right: 8px;
    left: 8px;
    display: flex;
  }

  &__super-badge {
    margin-left: auto;

    .icon-marmita {
      padding: 0;

      @include colorize-icon(#ef5753);
    }
  }
}
