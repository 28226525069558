@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-menu {
  &__overlay {
    background-color: rgba(#000, 0.28);
    z-index: $restaurant-dish-overlay;
  }

  &__modal {
    margin-left: auto;

    @include mq('tablet') {
      max-width: 660px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 8px 20px 16px;

    @include mq('tablet') {
      padding: 40px 42px 40px 50px;
    }
  }

  &__close-button {
    width: 40px;
    height: 40px;
    margin: 0;

    .icon-marmita {
      width: 30px;
      height: 30px;
    }
  }

  &__nav {
    padding: 0 16px 20px;

    @include mq('tablet') {
      padding: 0 50px 20px;
    }
  }

  &__nav-button {
    padding: 0;
    justify-content: flex-start;
    height: 38px;

    @include mq('mobile') {
      height: 50px;
    }
  }

  &__sing-in {
    margin: 12px 0;

    @include mq('tablet') {
      margin-top: 38px;
    }
  }

  &__sing-up {
    text-transform: lowercase;
  }
}
