@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-multi-categories {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &--low-opacity {
    opacity: 0.5;
  }

  &__wrapper {
    width: 90%;
    max-width: 842px;

    &::before {
      position: absolute;
      content: '';
      width: 100vw;
      height: 50%;
      background-color: $light-grey-color-200;
      z-index: -1;
      left: 0;
      bottom: 50%;
      transform: translateY(-50%);
    }
  }

  &__main-categories {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 180px;
    width: 100%;

    @include mq('desktop-s') {
      height: 214px;
    }
  }

  &__carousel {
    width: 90%;
    max-width: 842px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__big-category {
    width: 48%;
    transition: opacity 1.5s ease;
    height: 100%;
    cursor: pointer;
  }
}
