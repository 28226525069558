@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-address-search {
  padding-top: 83px;
  width: 100%;
  background-color: $light-grey-color-200;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  @include mq('tablet') {
    padding-top: 110px;
    padding-bottom: 35px;
  }

  @include mq('desktop-s') {
    padding-top: calc(136px + 4vh);
  }

  &__wrapper {
    width: 90%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 842px;
  }

  &__text {
    margin: 0 0 16px;

    @include mq('tablet') {
      margin: 0 0 14px;
    }
  }

  &__title {
    font-family: $font-family-title;
    margin-top: 15px;
    font-size: rem(18);
    font-weight: 500;

    @include mq('tablet') {
      font-size: rem(36);
    }
  }

  &__description {
    font-size: rem(14);
    font-weight: 400;
    color: $text-color;
    max-width: 200px;

    @include mq('tablet') {
      max-width: none;
      margin-bottom: 40px;
      font-size: rem(16);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;

    &-field {
      width: 100%;
      border-radius: 4px;
      height: 49px;
    }

    @include mq('tablet') {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__input-wrapper {
    background-color: #fff;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14px;
    border: 1px solid $light-grey-color-100;
    position: relative;

    @include mq('tablet') {
      width: 100%;
      margin-right: 20px;
    }
  }

  &__pin-icon {
    display: flex;
    width: 28px;
    margin-left: 5px;
    color: $primary-color;

    @include mq('tablet') {
      margin: 0 16px;
      height: 25px;
    }
  }

  &__input {
    padding-top: 4px;
    color: $text-color-lighten;
    font-weight: 100;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;

    @include mq('tablet') {
      margin-left: 4px;
    }

    &::placeholder {
      color: $text-color-lighten;
    }
  }

  &__button {
    border: none;
    cursor: pointer;
    font-size: rem(16);
    color: #fff;
    font-weight: 500;
    background-color: $primary-color;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);

    @include mq('tablet') {
      width: 168px;
    }
  }

  &__list-address {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    z-index: 2;
  }

  &__map-modal {
    height: 100%;
    padding: 0;

    @include mq('tablet') {
      height: calc(100vh - 100px);
      max-height: 584px;
      width: 694px;
    }

    .marmita-modal {
      &__inner-content {
        display: grid;
      }
    }

    &-overlay {
      & > .marmita-modal__content--after-open {
        border-radius: 0;
        overflow: hidden;

        @include mq('tablet') {
          top: 80px;
          transform: translateX(-50%);
          border-radius: $border-radius-default;
        }
      }
    }
  }
}
