@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $light-grey-color-200;
  z-index: $header-z-index;

  &--scrolled {
    box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  }

  &__container {
    display: grid;
    grid-template-columns: 40px 1fr 88px;
    padding: 20px 16px;
    column-gap: 16px;
    align-items: center;
    max-width: $new-lading-desktop-container;
    margin: 0 auto;
    position: relative;

    @include mq('tablet') {
      grid-template-columns: 40px 1fr 114px 104px;
      padding: 30px 44px 30px 30px;
      column-gap: 36px;
    }

    @include mq('desktop-s') {
      grid-template-columns: 88px 1fr 114px 104px;
      padding: 40px 32px;
      column-gap: 20px;
    }
  }

  &__brand {
    height: 43px;

    @include mq('tablet') {
      height: 48px;
    }

    .icon-brand {
      height: 100%;
    }
  }

  &__nav {
    display: none;

    @include mq('desktop-s') {
      display: flex;
    }
  }

  &__nav-button {
    padding: 0 10px;
    font-weight: 400;

    @include mq('desktop') {
      padding: 0 20px;
    }
  }

  &__sing-in,
  &__sing-up {
    height: 34px;
    border-radius: 6px;
    padding: 0;

    @include mq('tablet') {
      height: 44px;
    }
  }

  &__sing-up {
    display: none;
    text-transform: lowercase;

    @include mq('tablet') {
      display: inline-flex;
    }
  }

  .landing-sign-up-voucher {
    display: grid;
    position: absolute;
    right: 16px;
    top: 80px;
    animation: show-voucher 1.2s ease-in;

    @include mq('tablet', max) {
      left: 16px;
      width: auto;
    }

    @include mq('tablet') {
      max-width: 333px;
      margin: 0;
      right: 44px;
      top: 98px;
    }

    @include mq('desktop-s') {
      right: 32px;
      top: 110px;
    }

    &__close {
      display: grid;
    }
  }
}

@keyframes show-voucher {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  75% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
