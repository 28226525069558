@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.loading {
  height: 100vh;
}

.landing-v2 {
  max-width: $new-lading-desktop-container;
  margin: 0 auto;

  .highlights-carousel {
    &__wrapper {
      margin-bottom: 0;
    }

    @include mq('desktop-s') {
      padding: 0;
    }
  }

  &__footer {
    background: #fff;

    .footer {
      margin-top: 0;
    }

    .footer__section--cities {
      padding-top: 0;
    }

    &--cities {
      .footer {
        position: relative;

        @include thin-border-after($footer-border-color);

        @include mq('desktop-s') {
          @include thin-border-after($footer-border-color, $padding-default-desktop, $padding-default-desktop * 2);
        }

        @include mq('tablet', max) {
          &__section--cities .footer__links-list {
            grid-template-rows: repeat(18, min-content);
          }
        }
      }
    }
  }

  .footer__section:first-of-type {
    border: none;
  }

  &__animation-container {
    overflow: hidden;
  }

  &__main-container {
    margin: 0 auto;
    max-width: $new-lading-desktop-container;
  }

  .marmita-scrollbox {
    overflow: initial;
  }

  &__ads-top {
    margin-top: $space-green;
  }

  &__ads-footer {
    width: 100vw;
    margin-left: calc(((100vw - 1278px) / 2) * -1);
    margin-top: $space-orange;
    padding: $space-orange 0;
    background-color: $light-grey-color-100;
  }
}
