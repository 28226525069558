@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-groceries-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  width: 100%;
  margin-top: 80px;
  padding: 56px 0 136px;
  background-image: bucket-url('landing/groceries-background-mobile.png', 't_banner');
  background-repeat: no-repeat;
  background-position: center -15px;
  background-size: 100% auto;
  aspect-ratio: 2;

  @media (min-width: 768px) {
    justify-content: center;
    background-size: 125% auto;
    background-position: center 20px;
    background-image: url('/static/images/groceries-landing-page/desktop-background.webp');
  }

  @media (min-width: 900px) {
    background-position: center -15px;
    background-size: 100% auto;
  }

  &__logo {
    margin: 0;
    width: 85px;

    @media (min-width: 768px) {
      margin: 45px 0 55px;
      width: auto;

      .icon-brand {
        width: 87px;
      }
    }
  }

  &__texts {
    width: 95%;
  }

  &__text {
    color: $text-color-super-darken;
  }

  &__title {
    margin: 35px 0 10px;
    line-height: 24px;
    font-size: rem(24);
    font-weight: 500;

    @media (min-width: 768px) {
      font-size: rem(48);
      line-height: 100%;
    }
  }

  &__description {
    margin: 0 0 45px;
    font-size: rem(14);
    font-weight: 300;

    @media (min-width: 768px) {
      font-size: rem(18);
      margin: 0;
    }
  }

  &__form {
    width: 90%;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) {
      flex-direction: row;
      width: 600px;
      justify-content: space-between;
      margin: 25px 0;
    }
  }

  &__field {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: none;
  }

  &__button {
    cursor: pointer;
    background-color: $primary-color;
    font-size: rem(16);
    color: #fff;

    @media (min-width: 768px) {
      width: 38%;
    }
  }

  &__input-wrapper {
    background-color: $light-grey-color-100;
    align-items: center;
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      width: 60%;
    }
  }

  &__input {
    color: $text-color;
    font-weight: 100;
    width: 85%;
    background-color: transparent;
    border: none;
    margin-left: 6px;
  }

  &__search-icon {
    height: 16px;
    width: 16px;

    svg {
      color: $primary-color;
      stroke: $primary-color;
    }
  }

  &__sign-in {
    position: absolute;
    top: 10px;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;

    @media (min-width: 768px) {
      top: 35px;
    }

    &-icon {
      width: 20px;
      color: $text-color-super-darken;
      margin-right: 5px;
    }

    &-text {
      color: $text-color-super-darken;
      font-size: rem(16);
      line-height: 20px;
    }
  }

  &__tags {
    width: 90%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 28px 0;

    @media (min-width: 768px) {
      width: 600px;
      margin: 0;
    }
  }

  &__tag {
    margin: 4px 2px;
    background-color: #fff;
    color: $text-color;
    font-weight: 300;
    font-size: rem(14);
    border: 1px solid $light-grey-color-100;
    padding: 8px 12px;
    border-radius: 100px;
    line-height: 16px;
    cursor: pointer;

    @media (min-width: 768px) {
      margin: 4px;
    }
  }

  &__tag-wrapper {
    background: none;
    border: none;
    padding: 0;
  }
}
