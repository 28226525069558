@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-multi-categories-big-link {
  width: 100%;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  text-decoration: none;

  @include mq('desktop-s') {
    &__foods {
      position: absolute;
      width: 50vw;
      height: 100vh;
      animation: foods-animation 5s infinite;
      background-repeat: no-repeat;
      pointer-events: none;

      &--right {
        max-height: 0;
      }
    }
  }

  @include mq('desktop-wide') {
    &__foods {
      width: 30vw;

      &--right {
        right: 0;
        max-height: none;
      }

      &--left {
        left: 0;
      }
    }
  }

  &--market {
    background-color: #b6d048;
    transition: all 0.2s cubic-bezier(0, 0, 0.5, 1);

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
    }
  }

  &--restaurant {
    background-color: $primary-color;
    transition: all 0.2s cubic-bezier(0, 0, 0.5, 1);

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
    }
  }

  &__container {
    width: 80%;
    cursor: pointer;

    @include mq('tablet') {
      position: relative;
      display: flex;
      height: 100%;
      width: 90%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
    }
  }

  &__title {
    font-family: $font-family-title;
    font-size: rem(20);
    font-weight: 500;
    margin: 0 0 10px;

    @include mq('tablet') {
      font-size: rem(24);
    }

    @include mq('desktop-s') {
      font-size: rem(36);
    }
  }

  &__image-container {
    height: 75px;
    width: 90%;
    overflow: hidden;
    margin: 0 auto;

    @include mq('tablet') {
      overflow: visible;
      position: absolute;
      right: -14px;
      width: 62%;
      height: auto;
      bottom: 8px;
    }
  }

  &__image {
    object-fit: contain;
    resize: auto;
    height: 100%;
    width: 100%;

    @include mq('tablet') {
      transform: translateY(16px);
    }

    &--market {
      width: 90%;

      @include mq('tablet') {
        width: 100%;
      }
    }
  }

  &__link {
    display: flex;
    width: 100%;
    height: 36px;
    border-radius: 12px;
    font-size: rem(14);
    justify-content: space-around;
    font-weight: 500;
    align-items: center;
    padding: 0 5px;

    &--market {
      background-color: #9eb53e;
    }

    &--restaurant {
      background-color: #cc1825;
    }

    @include mq('tablet') {
      width: 118px;
      font-weight: 200;
      padding: 0 12px;
    }

    @include mq('desktop-s') {
      width: 146px;
      font-weight: 500;
      font-size: rem(16);
    }
  }

  &__icon {
    display: flex;

    svg {
      color: #fff;
      width: 14px;
      height: 10px;
    }

    g {
      stroke-width: 6px;

      @include mq('tablet') {
        stroke-width: 10px;
      }
    }
  }
}
