@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.food-lover-banner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
  border-top: 2px solid #f2f2f2;
  width: calc(100% - 32px);
  margin: 28px 16px 20px;

  @include mq('mobile') {
    margin: 40.95px 16px 20px;
  }

  @include mq('tablet') {
    display: grid;
    grid-template: auto 1fr / 1fr auto;
    grid-template-areas:
      'title image'
      'content image';
    gap: 28px 60px;
    padding: 80px 0;
    margin: 60.76px 45px 0;
    width: unset;
  }

  @media only screen and (min-width: 1024px) {
    padding: 80px calc((108 / (1278 - 1024)) * (100vw - 1024px));
    gap: 40px 51.2px;
    margin: 80.76px 32px 0;
  }

  @media only screen and (min-width: 1278px) {
    padding: 80px 108px;
    margin: 0;
  }

  &__title {
    font-size: rem(18);
    line-height: rem(22);
    text-align: center;
    margin: 0;
    font-weight: 500;

    @include mq('mobile') {
      font-size: rem(20);
      line-height: rem(26);
    }

    @include mq('tablet') {
      grid-area: title;
      font-size: rem(24);
      line-height: rem(30);
      text-align: left;
      max-width: 180px;
    }

    @media only screen and (min-width: 1024px) {
      font-size: rem(36);
      line-height: rem(44);
    }
  }

  &__image {
    width: 442px;
    height: 224.25px;
    margin-left: -49px;

    @media only screen and (min-width: 409px) {
      margin-left: calc(100vw - 458px);
    }

    @media only screen and (min-width: 442px) {
      margin-left: unset;
      align-self: center;
    }

    @include mq('tablet') {
      grid-area: image;
      width: calc((0.7 * (100vw - 768px)) + 442px);
      height: calc((0.35 * (100vw - 768px)) + 224.25px);
      max-width: 623px;
      max-height: 316.08px;
    }

    @media only screen and (min-width: 1024px) {
      width: calc((0.425 * (100vw - 1024px)) + 623px);
      height: calc((0.21 * (100vw - 1024px)) + 316.08px);
      max-width: 731.8px;
      max-height: 371.28px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    @include mq('tablet') {
      grid-area: content;
      align-items: unset;
      gap: 20px;
    }

    &-text {
      text-align: center;
      color: #717171;
      max-width: 280px;
      margin: 0;

      @include mq('tablet') {
        font-size: rem(16);
        line-height: rem(20);
        text-align: left;
        max-width: unset;
      }
    }

    &-action {
      background-color: #ea1d2c;
      color: white;
      border-radius: 6px;
      padding: 8px 16px;
      font-size: rem(14);
      line-height: rem(18);
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      width: 102px;

      @include mq('tablet') {
        font-size: rem(16);
        line-height: rem(19.79);
        padding: 12px 20px;
        width: 120px;
      }
    }
  }
}
