@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-multi-categories-small-link {
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0, 0, 0.5, 1);

  figure {
    transition: all 0.2s cubic-bezier(0, 0, 0.5, 1);
  }

  &:hover {
    transform: scale(1.02);

    figure {
      box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
    }
  }

  @include mq('tablet') {
    height: 130px;
    margin: 0;
  }

  @include mq('desktop-s') {
    height: 160px;
  }

  &__image-wrapper {
    margin: 0;
    padding: 0;
    width: 96px;
    height: 24px;
    border-radius: 6px;
    position: relative;

    &--drinks {
      background-color: #f6d553;
    }

    &--pharmacy {
      background-color: #f9879c;
    }

    &--petshop {
      background-color: #8c60c5;
    }

    &--express {
      background-color: #ea1d2c;
    }

    @include mq('tablet') {
      width: 150px;
      height: 38px;
    }

    @include mq('desktop-s') {
      width: 188px;
      height: 47px;
    }
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);

    &--drinks {
      width: 74px;

      @include mq('tablet') {
        width: 120px;
      }

      @include mq('desktop-s') {
        width: 140px;
      }
    }

    &--pharmacy {
      width: 56px;

      @include mq('tablet') {
        width: 82px;
      }

      @include mq('desktop-s') {
        width: 100px;
      }
    }

    &--express {
      width: 54px;

      @include mq('tablet') {
        width: 80px;
      }

      @include mq('desktop-s') {
        width: 96px;
      }
    }

    &--petshop {
      width: 70px;

      @include mq('tablet') {
        width: 110px;
      }

      @include mq('desktop-s') {
        width: 135px;
      }
    }
  }

  &__title {
    margin: 10px 0 0;
    text-align: center;
    font-size: rem(14);
    display: flex;
    color: $gray-color-700;

    @include mq('tablet') {
      font-weight: 500;
      font-size: rem(16);
    }

    @include mq('desktop-s') {
      margin-top: 12px;
    }
  }

  &__icon {
    svg {
      margin-left: 5px;
      color: $primary-color;
      font-weight: bold;
      width: 8px;
    }

    g {
      stroke-width: 15px;
    }
  }
}
