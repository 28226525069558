@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-v2-ifood-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mq('desktop-s') {
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    max-width: 947px;
    justify-content: space-between;
  }

  @include mq('desktop') {
    max-width: 1050px;
    margin-bottom: 80px;
  }
}
